"use client";
import { Navigation as UINavigation } from "@yardzen/ui";
import { useSession } from "next-auth/react";
import { links, menuLinks } from "./navigationLinks";

export function ClientNavigation() {
  const { data: session } = useSession();
  const isLoggedIn = !!session?.user;

  return (
    <UINavigation links={links} menuLinks={menuLinks} loggedIn={isLoggedIn} />
  );
}
