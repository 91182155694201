"use client";

import { useState } from "react";
import { Checkbox } from "@yardzen/components/checkbox";
import { YardzenButton } from "@yardzen/components/yardzen-button";
import { useCreateCurrentUserTermsOfServiceMutation } from "@yardzen/components-gql";
import { useRouter } from "next/navigation";

interface AcceptTermsProps {
  userId: string;
}

export function AcceptTermsForm(props: AcceptTermsProps) {
  const router = useRouter();
  const [tosChecked, setTosChecked] = useState(false);
  const [createTermsOfService, { loading: updateSaving }] =
    useCreateCurrentUserTermsOfServiceMutation();

  const handleSubmission = async () => {
    try {
      await createTermsOfService({
        variables: {
          input: {
            userId: props.userId,
            tosAgreed: tosChecked,
            dateOfAgree: new Date().toISOString(),
          },
        },
      });
      router.refresh();
    } catch (error) {
      console.error("Error saving terms of service agreement", error);
    }
  };

  return (
    <div>
      <Checkbox
        id="terms-of-service-agreement"
        ariaLabel="terms-of-service-agreement"
        checked={tosChecked}
        onChange={(e) => setTosChecked(e.target.checked)}
        wrapperClassName="flex gap-2 items-start pt-4"
        labelClassName="text-typo-primary text-sm md:text-base"
        label="I agree to Yardzen's <a href='https://yardzen.com/electronic-signature-disclosures' target='_blank' style='text-decoration: underline; color: rgb(29 78 216);'>Consent to Electronic Signature and Disclosures,</a> <a href='https://yardzen.com/terms-and-conditions' target='_blank' style='text-decoration: underline; color: rgb(29 78 216);'>Terms of Service</a> and <a href='https://yardzen.com/privacy-policy' target='_blank' style='text-decoration: underline; color: rgb(29 78 216);'>Privacy Policy</a>"
      ></Checkbox>
      <div className="flex flex-col items-center ">
        <YardzenButton
          disabled={!tosChecked}
          onClick={handleSubmission}
          loading={updateSaving}
          sx={{ marginTop: "2rem", width: "50%" }}
        >
          Continue
        </YardzenButton>
      </div>
    </div>
  );
}
