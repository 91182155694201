import { ExportResult, ExportResultCode } from "@opentelemetry/core";
import {
  Span,
  SpanProcessor,
  SpanExporter,
  ReadableSpan,
} from "@opentelemetry/sdk-trace-base";
import LogRocket from "logrocket";

export class LogRocketSpanExporter implements SpanExporter {
  private readonly reportedTraces = new Map<string, Set<string>>();

  private getReportedSet(sessionUrl: string, m: Map<string, Set<string>>) {
    if (!m.has(sessionUrl)) {
      m.set(sessionUrl, new Set<string>());
    }
    return m.get(sessionUrl) as Set<string>;
  }

  public export(
    _spans: ReadableSpan[],
    resultCallback: (result: ExportResult) => void,
  ): void {
    if (typeof window === "undefined") {
      resultCallback({ code: ExportResultCode.SUCCESS });
      return;
    }

    try {
      const sessionUrl = sessionStorage.getItem("logRocket.sessionURL");
      if (!sessionUrl) {
        resultCallback({ code: ExportResultCode.SUCCESS });
        return;
      }

      // error spans only
      const spans = _spans.filter((span) => span.status.code === 2);

      const traceIds = new Set<string>();
      const reportedTraces = this.getReportedSet(
        sessionUrl,
        this.reportedTraces,
      );

      for (const span of spans) {
        if (!reportedTraces.has(span.spanContext().traceId)) {
          traceIds.add(span.spanContext().traceId);
          reportedTraces.add(span.spanContext().traceId);
        }
      }

      if (traceIds.size === 0) {
        resultCallback({ code: ExportResultCode.SUCCESS });
        return;
      }

      LogRocket.track("OpenTelemetryTraces", {
        traceIds: Array.from(traceIds),
      });

      resultCallback({ code: ExportResultCode.SUCCESS });
    } catch (e) {
      console.error("Error exporting spans to LogRocket", e);
      resultCallback({
        code: ExportResultCode.FAILED,
        error: e as Error | undefined,
      });
    }
  }

  public async shutdown(): Promise<void> {
    void 0;
  }
  public async forceFlush?(): Promise<void> {
    void 0;
  }
}
export class LogRocketSpanProcessor implements SpanProcessor {
  public onStart(span: Span): void {
    this.addSessionUrlToSpan(span);
  }

  public onEnd(span: Span): void {
    this.addSessionUrlToSpan(span);
  }

  public async forceFlush(): Promise<void> {
    void 0;
  }

  public async shutdown(): Promise<void> {
    void 0;
  }

  private addSessionUrlToSpan(span: Span) {
    if (typeof window !== "undefined") {
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const sessionUrl = sessionStorage.getItem("logRocket.sessionURL");
        if (!sessionUrl) {
          return;
        }
        span.setAttribute("logrocket.session_url", sessionUrl);
      } catch (e) {
        console.error("Error adding span information to logRocket", e);
      }
    }
  }
}
