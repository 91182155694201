export const hasNumberRegExp = /[0-9]+/;
export const hasLowerCaseLetterRegExp = /[a-z]+/;
export const hasUpperCaseLetterRegExp = /[A-Z]+/;
export const hasSpecialCharRegExp = /[-+_!@#$%^&*.,?]/;
export const hasMinimum6CharsRegExp = /.{6,}/;

export const passwordRequirements = (password: string) => {
  const hasNumber = password.match(hasNumberRegExp);
  const hasLowerCaseLetter = password.match(hasLowerCaseLetterRegExp);
  const hasUpperCaseLetter = password.match(hasUpperCaseLetterRegExp);
  const hasSpecialChar = password.match(hasSpecialCharRegExp);
  const hasMinimum6Chars = password.match(hasMinimum6CharsRegExp);

  return [
    { text: "One Lowercase letter", requirement: hasLowerCaseLetter },
    { text: "One Uppercase letter", requirement: hasUpperCaseLetter },
    { text: "One number", requirement: hasNumber },
    {
      text: `One special character `,
      element: true,
      requirement: hasSpecialChar,
    },
    { text: "Six characters", requirement: hasMinimum6Chars },
  ];
};
