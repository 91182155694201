import Script from "next/script";
const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GOOGLE_TRACKING_ID ?? "";

// I believe https://github.com/DefinitelyTyped/DefinitelyTyped/ used to have gtag
// but does not appear to be available anymore.
declare global {
  interface Window {
    gtag: (
      command: "event",
      eventName: string,
      eventData: { [key: string]: any },
    ) => void;
  }
}

// Before using this function, check to see if you can send the data through Segment instead
export const sendGaEvent = (
  eventName: string,
  data: { [key: string]: any },
) => {
  if (window.gtag) {
    window.gtag("event", eventName, data);
  } else {
    console.error(`gtag is not available`);
  }
};

export function GoogleAnalytics({ enableDebug = false }) {
  const configObj = {
    debug_mode: enableDebug,
  };

  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <Script id="ga4" strategy="afterInteractive">
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag("js", new Date());
        gtag("config", "${GA_TRACKING_ID}", ${JSON.stringify(configObj)});
        `}
      </Script>
    </>
  );
}
