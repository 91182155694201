export const standardClass = {
  backgroundColor: "prosTheme.button.primary",
  borderRadius: "1.25rem",
  "&:hover": {
    backgroundColor: "prosTheme.button.hover",
  },
  textTransform: "none",
  py: "12px",
  color: "primary.contrastText",
  fontSize: "14px",
  fontWeight: 600,
  marginTop: "1rem",
};

export const outlinedClass = {
  color: "prosTheme.button.primary",
  backgroundColor: "transparent",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "prosTheme.button.primary",
  "&:hover": {
    backgroundColor: "transparent",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "prosTheme.button.primary",
  },
};

export function buttonClass(variant = "standard") {
  if (variant === "outlined") {
    return {
      ...standardClass,
      ...outlinedClass,
    };
  }

  return standardClass;
}
