"use client";

import LogRocketLib from "logrocket";

const accountId = "snab0e";

interface Props {
  appName: "build-marketplace" | "pros" | "yardai";
}

export function LogRocket({ appName }: Props) {
  const siteName = `${accountId}/${appName}`;
  const logRocketTrackingEnabled =
    process.env.NODE_ENV === "production" ||
    process.env.NEXT_PUBLIC_LOGROCKET_ENABLED === "true";

  if (typeof window !== "undefined" && logRocketTrackingEnabled) {
    try {
      LogRocketLib.init(siteName);
      LogRocketLib.getSessionURL((sessionURL) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sessionStorage.setItem("logRocket.sessionURL", sessionURL);
      });
    } catch (e) {
      console.error("Error initializing LogRocket", e);
    }
  }

  return null;
}
