"use client";

import { datadogRum } from "@datadog/browser-rum";
import type { FC } from "react";
import { useEffect, useRef } from "react";

export const Datadog: FC<{
  service: string;
  treatments?: Record<string, string>;
}> = ({ service, treatments }) => {
  const version = process.env.NEXT_PUBLIC_COMMIT_SHA || "development";
  const lastVersion = useRef(version);

  useEffect(() => {
    try {
      console.log("[Datadog] Version:", version);

      // Only initialize if not already initialized or if version changed
      if (!datadogRum.getInternalContext() || lastVersion.current !== version) {
        lastVersion.current = version;
        datadogRum.init({
          applicationId: "85dc5232-2f23-40db-96b4-e2ff39c2df6f",
          clientToken: "pub3a41b898b58be888681bd1763723c3a6",
          site: "us5.datadoghq.com",
          service,
          env: process.env.NODE_ENV || "development",
          version,
          sessionSampleRate: 100,
          sessionReplaySampleRate: 100,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel: "allow",
          enableExperimentalFeatures: ["feature_flags"],
        });

        datadogRum.startSessionReplayRecording();
        console.log(
          "[Datadog] RUM initialized successfully with version:",
          version,
        );
      }

      if (treatments) {
        console.log("[Datadog] Setting feature flag evaluations:", treatments);
        Object.entries(treatments).forEach(([feature, treatment]) => {
          datadogRum.addFeatureFlagEvaluation(feature, treatment);
        });
      }
    } catch (error) {
      console.error("[Datadog] Failed to initialize:", error);
    }
  }, [service, treatments, version]);

  return null;
};
