"use client";

import { FC } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { NextAppDirEmotionCacheProvider } from "tss-react/next/appDir";
import { outlinedInputClasses } from "@mui/material";
import { yardzenPalette } from "./yardzenPalette";

interface ThemeProviderProps {
  children: React.ReactNode;
}

// allow for custom theme options
declare module "@mui/material/styles" {
  interface TypeText {
    hint?: TypeText["primary"];
  }
  interface TypeBackground {
    buttonDark: TypeBackground["default"];
  }

  interface TypographyVariants {
    subhead1: React.CSSProperties;
    subhead1_bold: React.CSSProperties;
    subhead2: React.CSSProperties;
    body_lg: React.CSSProperties;
    body: React.CSSProperties;
    body_sm: React.CSSProperties;
    body_xs: React.CSSProperties;
    small_caps: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    subhead1: React.CSSProperties;
    subhead1_bold: React.CSSProperties;
    subhead2: React.CSSProperties;
    body_lg: React.CSSProperties;
    body: React.CSSProperties;
    body_sm: React.CSSProperties;
    body_xs: React.CSSProperties;
    small_caps: React.CSSProperties;
  }
}
// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    subhead1: true;
    subhead1_bold: true;
    subhead2: true;
    body_lg: true;
    body: true;
    body_sm: true;
    body_xs: true;
    small_caps: true;
  }
}
// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    subhead1: true;
    subhead1_bold: true;
    subhead2: true;
    body_lg: true;
    body: true;
    body_sm: true;
    body_xs: true;
  }
}

export const theme = createTheme({
  typography: {
    h1: {
      fontFamily: "var(--font-arsenal)",
      fontSize: "3.75rem",
      fontWeight: 400,
    },
    h2: {
      fontFamily: "var(--font-arsenal)",
      fontSize: "2.25rem",
      fontWeight: 400,
    },
    h3: {
      fontFamily: "var(--font-arsenal)",
      fontSize: "1.6875rem",
      fontWeight: 400,
    },
    h4: {
      fontFamily: "var(--font-arsenal)",
      fontSize: "1.365rem",
      fontWeight: 400,
    },
    subhead1: {
      fontFamily: "var(--font-roboto)",
      fontSize: "1.375rem",
      fontWeight: 400,
    },
    subhead1_bold: {
      fontFamily: "var(--font-roboto)",
      fontSize: "1.375rem",
      fontWeight: 500,
    },
    subhead2: {
      fontFamily: "var(--font-roboto)",
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: "1.5rem",
      letterSpacing: "0.03125rem",
    },
    body_lg: {
      fontFamily: "var(--font-roboto)",
      fontSize: "1.125rem",
      fontWeight: 400,
      lineHeight: "1.5rem",
    },
    body: {
      fontFamily: "var(--font-roboto)",
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "1.5rem",
      letterSpacing: "0.03125rem",
    },
    body_sm: {
      fontFamily: "var(--font-roboto)",
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "1.5rem",
      letterSpacing: "0.03125rem",
    },
    body_xs: {
      fontFamily: "var(--font-roboto)",
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: "1.5rem",
      letterSpacing: "0.03125rem",
    },
    small_caps: {
      fontFamily: "var(--font-roboto)",
      fontSize: "0.875rem",
      fontWeight: 600,
      lineHeight: "1.3125rem",
      letterSpacing: "0.03125rem",
      fontVariant: "all-small-caps",
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "var(--TextField-brandBorderColor)",
        },
        root: {
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: "var(--TextField-brandBorderHoverColor)",
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: "var(--TextField-brandBorderFocusedColor)",
          },
        },
      },
    },
  },
  palette: yardzenPalette,
});

export const YzThemeProvider: FC<ThemeProviderProps> = ({
  children,
}: ThemeProviderProps) => {
  return (
    <NextAppDirEmotionCacheProvider options={{ key: "css" }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </NextAppDirEmotionCacheProvider>
  );
};
