import { startCase } from "lodash";

const formatMilestone = (milestone: string | null | undefined) => {
  if (!milestone) {
    return "N/A";
  }

  return milestone.toLowerCase().split("_").map(startCase).join(" ");
};

export { formatMilestone };
