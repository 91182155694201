type Link = {
  fields: {
    title: string;
    url: string;
  };
};

export const links: Link[] = [
  {
    fields: {
      title: "Shop Packages",
      url: "https://yardzen.com/packages",
    },
  },
  {
    fields: {
      title: "How It Works",
      url: "https://yardzen.com/how-it-works",
    },
  },
];

export const menuLinks: Link[] = [
  {
    fields: {
      title: "Design Packages",
      url: "https://yardzen.com/packages",
    },
  },
  {
    fields: {
      title: "How It Works",
      url: "https://yardzen.com/how-it-works",
    },
  },
  {
    fields: {
      title: "Design Gallery",
      url: "https://project.yardzen.com/gallery",
    },
  },
  {
    fields: {
      title: "Sustainability",
      url: "https://yardzen.com/american-rewilding-project",
    },
  },
  {
    fields: {
      title: "About Us",
      url: "https://yardzen.com/about",
    },
  },
  {
    fields: {
      title: "FAQs",
      url: "https://yardzen.com/faq",
    },
  },
  {
    fields: {
      title: "Client Login",
      url: "https://project.yardzen.com/login",
    },
  },
];
