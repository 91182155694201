"use client";

import { analytics } from "@yardzen/next-segment";
import { ChangeEventHandler } from "react";

interface CheckboxProps {
  id?: string;
  ariaLabel?: string;
  name?: string;
  checked: boolean;
  disabled?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement> | undefined;
  label: string;
  labelClassName?: string;
  wrapperClassName?: string;
  trackingData?: {
    button_name: string;
    flow_name: string;
  };
}

/**
 * Checkbox component renders a customizable checkbox input element with an associated label.
 * It supports enabling, disabling, and handling change events.
 *
 * @param {CheckboxProps} props - The properties passed to the component.
 * @param {string} props.id - Optional. The unique identifier for the checkbox, used optionally to associate the label.
 * @param {string} [props.name="checkbox"] - The name attribute for the checkbox. Useful when submitting a form.
 * @param {boolean} props.checked - Indicates whether the checkbox is initially checked.
 * @param {boolean} [props.disabled=false] - If true, the checkbox will be disabled and not clickable.
 * @param {ChangeEventHandler<HTMLInputElement> | undefined} props.onChange - The function to call when the checkbox's checked state changes.
 * @param {string} props.label - The text to be displayed alongside the checkbox as its label.
 * @param {string} [props.labelClassName] - Optional custom CSS class for styling the label.
 * @param {string} [props.wrapperClassName] - Optional custom CSS class for styling the wrapper div around the checkbox and label.
 * @returns {JSX.Element} The rendered Checkbox component.
 */
export function Checkbox({
  id,
  ariaLabel,
  name,
  checked,
  disabled,
  onChange,
  label,
  labelClassName,
  wrapperClassName,
  trackingData,
}: CheckboxProps) {
  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (trackingData) {
      analytics.trackClicked({
        ...trackingData,
        click_type: "checkbox-button",
        button_content: label,
      });
    }

    onChange?.(ev);
  };

  return (
    <div className={wrapperClassName ?? "mb-2 flex items-center"}>
      <label
        className={
          labelClassName ?? "h-5 text-base font-semibold text-neutral-700"
        }
      >
        <input
          type="checkbox"
          id={id}
          aria-label={ariaLabel}
          name={name ?? "checkbox"}
          checked={checked}
          onChange={handleChange}
          className="mr-[.5rem] h-5 w-5 appearance-none rounded border-2 border-solid border-[#49454F]  text-[#49454F] checked:text-[#49454F]"
          disabled={disabled}
        />
        <span dangerouslySetInnerHTML={{ __html: label }} />
      </label>
    </div>
  );
}
