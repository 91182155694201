export const extractGeocoderResults = async (
  geolocation: google.maps.GeocoderResult,
) => {
  if (!geolocation?.address_components) {
    throw new Error("no address components found");
  }

  let streetNumber = "";
  let route = "";
  let city = "";
  let state = "";
  let zip = "";
  let country = "";
  let county = "";
  let apt = "";

  for (const addressComponent of geolocation.address_components) {
    if (addressComponent.types.includes("street_number")) {
      streetNumber = addressComponent.short_name;
    } else if (addressComponent.types.includes("route")) {
      route = addressComponent.short_name;
    } else if (addressComponent.types.includes("subpremise")) {
      apt = addressComponent.short_name;
    } else if (addressComponent.types.includes("locality")) {
      city = addressComponent.long_name;
    } else if (addressComponent.types.includes("administrative_area_level_1")) {
      state = addressComponent.short_name;
    } else if (addressComponent.types.includes("administrative_area_level_2")) {
      county = addressComponent.long_name;
    } else if (addressComponent.types.includes("postal_code")) {
      zip = addressComponent.long_name;
    } else if (addressComponent.types.includes("country")) {
      country = addressComponent.short_name;
    }
  }

  if (!city) {
    for (const addressComponent of geolocation.address_components) {
      if (
        addressComponent.types.some((type: any) =>
          ["neighborhood", "administrative_area_level_3"].includes(type),
        )
      ) {
        city = addressComponent.long_name;
      }
    }
  }

  return {
    zip,
    streetNumber,
    route,
    apt,
    city,
    country,
    county,
    state,
    latLng: {
      lat: geolocation.geometry.location.lat(),
      lng: geolocation.geometry.location.lng(),
    },
    formattedAddress: geolocation.formatted_address,
  };
};
