const USDollarFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

const USDollarWithCentsFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const formatAsUsDollars = (
  amountInCents?: number,
  showCents = true,
): string => {
  if (!(typeof amountInCents === "number")) return "";

  const dollars = amountInCents / 100;

  return (showCents ? USDollarWithCentsFormat : USDollarFormat).format(dollars);
};
