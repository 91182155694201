export const publicStorageURL =
  "https://storage.googleapis.com/yardzen-public-assets/client-app";

export enum SegmentForms {
  FREE_PROJECT_PLANNER_SIGN_UP = "Free Project Planner Sign Up",
  SIGN_UP_AND_MESSAGE_PRO = "Sign Up and Message Pro",
  FREE_PROJECT_PLANNER_LOGIN = "Free Project Planner Login",
  FORGOT_PASSWORD = "Forgot Password",
  GALLERY_LIKES_LOGIN = "Gallery Likes Login",
  LEAD_CAPTURE = "Lead Capture",
  USER_PROFILE = "User Profile",
  STYLE_FINDER = "Style Finder",
  SELECT_PROJECT_FEATURES = "Select Project Features",
  PRO_MATCHES_LOGIN = "Pro Matches Login",
  CONTRACTOR_PROFILE_LOGIN = "Contractor Profile Login",
  YARADI_SHARE_IMAGE_FORM = "Yardai Share Image",
  YARDAI_STYLES_FORM = "YardAI Styles Form",
  YARDAI_EMAIL_CAPTURE_FORM = "YardAI Email Capture Form",
  SITEWALK_SCHEDULE = "Site Walk Schedule",
  INPSIRATION = "Inspiration",
  BUILD_STUDIO_QUIZ = "Build Studio Quiz",
  BUILD_STUDIO_SITE_WALK_FALLBACK = "Build Studio Site Walk Fallback",
  BUILD_STUDIO_SITE_WALK_CALENDLY = "Build Studio Site Walk Calendly",
  DESIGN_PROFILE_QUIZ = "Design Profile Quiz",
  CHECKOUT_PACKAGE_DETAILS = "Checkout Package Details",
}

// See: https://yardzen.atlassian.net/browse/BUI-501
export enum SegmentFlows {
  SIGN_IN = "Sign In",
  SIGN_UP = "Sign Up",
  PRO_MATCHES = "Pro Matches", // /select-project-features
  FORGOT_PASSWORD = "Forgot Password",
  STYLE_FINDER = "Style Finder", // /style-finder
  BUDGET_CALCULATOR = "Budget Calculator", // /build-project/select-project-features
  YARDAI = "YardAI",
  TOP_NAV = "Top Nav",
  PRO_PROFILE = "Pro Profile",
  HOME = "Marketing Home",
  FOOTER = "Page Footer",
  BANNER = "Page Banner",
  BUILD_STUDIO_QUIZ = "Build Studio Quiz",
  BUILD_STUDIO_SITE_WALK = "Build Studio Site Walk",
  PACKAGES = "Packages",
  HOW_IT_WORKS = "How It Works",
  PRO_DESIGNS = "Pro Designs",
  PACKAGE_DETAIL_FULL_YARD = "Package Detail - Full Yard",
  PACKAGE_DETAIL_FRONT_YARD = "Package Detail - Front Yard",
  PACKAGE_DETAIL_BACK_YARD = "Package Detail - Back Yard",
  PACKAGE_DETAIL_CURB_APPEAL = "Package Detail - Curb Appeal",
  PACKAGE_DETAIL_OUTDOOR_TRANSFORMATION = "Package Detail - Outdoor Transformation",
  PACKAGE_DETAIL_BOTANICAL = "Package Detail - Botanical",
  PACKAGE_DETAIL_PREMIUM = "Package Detail - Premium",
  DESIGN_PROFILE_QUIZ = "Design Profile Quiz",
  CHECKOUT = "Checkout",

  // Studio McGee
  STUDIO_MCGEE = "Studio McGee",
  STUDIO_MCGEE_PACKAGE_DETAIL_FULL_YARD = "Studio McGee - Package Detail - Full Yard",
  STUDIO_MCGEE_PACKAGE_DETAIL_FRONT_YARD = "Studio McGee - Package Detail - Front Yard",
  STUDIO_MCGEE_PACKAGE_DETAIL_BACK_YARD = "Studio McGee - Package Detail - Back Yard",
  STUDIO_MCGEE_PACKAGE_DETAIL_CURB_APPEAL = "Studio McGee - Package Detail - Curb Appeal",
  STUDIO_MCGEE_PACKAGE_DETAIL_OUTDOOR_TRANSFORMATION = "Studio McGee - Package Detail - Outdoor Transformation",
}

export enum SegmentSubFlows {
  LEAD_CAPTURE = "lead_capture",
}

export const YARDZEN_TERMS_AND_CONDITIONS_URL =
  "https://yardzen.com/terms-and-conditions";
