import { PaletteOptions } from "@mui/material/styles";

export type YardzenPalette = PaletteOptions & {
  background: PaletteOptions["background"] & {
    primary?: string;
    disabled?: string;
    light?: string;
    hover?: string;
    opacity?: { 15?: string };
    icon?: string;
  };
} & {
  action?: { main?: string };
} & {
  prosTheme: Omit<YardzenPalette, "prosTheme">;
};

export const yardzenPalette: YardzenPalette = {
  background: {
    default: "#FFF",
    paper: "#ffffff",
    primary: "#F6F5F4",
    buttonDark: "#323232",
  },
  button: {
    primary: "#1B6245",
    disabled: "#C4C4C4",
    hover: "#1E553F",
  },
  text: {
    primary: "#323232",
    secondary: "#000000",
    disabled: "#323232",
    hint: "#7a7a7a",
  },
  primary: {
    main: "#c3cbbe",
    light: "#e5e8e3",
    dark: "#4e5a44",
    contrastText: "#ffffff",
  },
  secondary: {
    main: "#7C8971",
    light: "#d9e4d4",
    dark: "#3d5730",
    contrastText: "#ffffff",
  },
  error: {
    main: "#c05757",
    light: "#e2d2d2",
    dark: "#a04444",
    contrastText: "#ffffff",
  },
  success: {
    main: "#2e7d32",
    contrastText: "#ffffff",
  },
  warning: {
    main: "#e1caa7",
    light: "#e2d2d2",
    dark: "#9c1717",
    contrastText: "#323232",
  },
  action: {
    main: "#3d6b52",
  },
  prosTheme: {
    background: {
      default: "#ffffff",
      paper: "#fafafa",
      buttonDark: "#303030",
      disabled: "#EBF3B9",
      light: "#7CAA1C26",
      hover: "#6FA494",
      opacity: {
        15: "rgba(124, 170, 28, 0.15)",
      },
      icon: "rgba(120, 165, 140, 0.5)",
    },
    button: {
      primary: "#1B6245",
      disabled: "#C4C4C4",
      hover: "#1E553F",
    },
    chip: {
      background: "#ecf2ea",
      text: "#3d6b52",
      secondary: "#C5D3CB",
    },
    text: {
      primary: "#000000",
      secondary: "#323232",
      disabled: "#4a4a4a",
      hint: "#7a7a7a",
      button: "#ffffff",
    },
    primary: {
      main: "#d8e9e4",
      light: "#eff6f4",
      dark: "#277860",
      opacity: {
        30: "rgba(39, 120, 96, 0.30)",
        60: "rgba(39, 120, 96, 0.60)",
      },
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#c1da29",
      light: "#e2e986",
      dark: "#93a13b",
      divider: "#a9c116",
      opacity: {
        30: "rgba(169, 193, 22, 0.30)",
      },
      contrastText: "#ffffff",
    },
    error: {
      main: "#c05757",
      light: "#f2dfd6",
      dark: "#c2400a",
      contrastText: "#ffffff",
    },
    success: {
      main: "#9ab75e",
      light: "#d6e5b5",
      dark: "#577812",
      contrastText: "#ffffff",
    },
    warning: {
      main: "#f08455",
      light: "#f4b295",
      dark: "#c65626",
      contrastText: "#000000",
    },
    info: {
      main: "#e1f17d",
      light: "#e3ead4",
      dark: "#7b8d0e",
      contrastText: "#000000",
    },
    divider: "#606060",
    status: { completed: "#9EB60B", inProgress: "#E0612A" },
  },
} as YardzenPalette;
