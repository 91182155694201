"use client";
import Cookies from "js-cookie";
import { PromoCode } from "./PromoCodeManagement";

export function PromoCodeController({
  discountCode,
}: {
  discountCode: PromoCode;
}) {
  Cookies.set("yzpdc", JSON.stringify(discountCode));

  return null;
}
