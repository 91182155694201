import type { IconDefinition } from "@fortawesome/fontawesome-common-types";

export function isIconDefinition(icon: unknown): icon is IconDefinition {
  return (
    typeof icon === "object" &&
    icon !== null &&
    "iconName" in icon &&
    "prefix" in icon &&
    "icon" in icon
  );
}
