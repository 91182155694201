import LogRocket from "logrocket";

export const identifyLogRocketUser = (
  userId: string,
  emailAddress?: string,
) => {
  const logRocketTrackingEnabled = process.env.NODE_ENV === "production";
  if (typeof window !== "undefined" && userId && logRocketTrackingEnabled) {
    const email = emailAddress || "";

    LogRocket.identify(userId, {
      email,
      userId,
    });
  }
};
