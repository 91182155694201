export const getFilenameFromUri = (
  uri: string,
  maxLength = 20,
  returnValueOnError?: string,
) => {
  try {
    const url = new URL(uri);
    const pathname = decodeURIComponent(url.pathname);
    const segments = pathname.split("/");
    const filename = segments.pop();

    if (filename?.length && filename.length > maxLength) {
      const partLength = Math.floor((maxLength - 3) / 2);
      const firstPart = filename.substring(0, partLength);
      const lastPart = filename.substring(filename.length - partLength);
      return `${firstPart}...${lastPart}`;
    }

    return filename;
  } catch (error) {
    console.error("Error getting filename from uri", error);
    return returnValueOnError || "file.pdf";
  }
};
