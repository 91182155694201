import { Loader } from "@googlemaps/js-api-loader";

export async function getCoordinatesByAddress(
  address: string,
): Promise<{ lat: number; lng: number }> {
  const loader = new Loader({
    apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || "",
    version: "quarterly",
  });
  const geocodingLibrary = await loader.importLibrary("geocoding");
  const geocoder = new geocodingLibrary.Geocoder();
  let results: google.maps.GeocoderResult[] = [];

  try {
    results = (await geocoder.geocode({ address }))?.results;
  } catch (e) {
    console.error("Failed to get coordinates by address", e);
    return {
      lat: 0,
      lng: 0,
    };
  }

  const location = results[0].geometry.location;
  return {
    lat: location.lat(),
    lng: location.lng(),
  };
}
