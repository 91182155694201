import { AcceptTermsForm } from "./AcceptTermsForm";

interface UserTermsOfServiceProps {
  userId: string;
}

export const CURRENT_TERMS_OF_SERVICE = "terms-of-service-april-2024";

export const UserTermsOfService = ({ userId }: UserTermsOfServiceProps) => {
  return (
    <div className="flex flex-col items-center justify-center pt-20">
      <div className="m-6 lg:max-w-2xl">
        <h1 className="font-display text-typo-primary text-4xl">
          Changes to Yardzen's Terms & Privacy Policy
        </h1>
        <p className="pt-6">
          Please review our Terms of Service and Privacy Policy which govern
          your access to and use of our services, including our website, mobile
          applications, and any other products or services offered by Yardzen.
          By accessing the services, you agree to Yardzen's{" "}
          <a
            href="https://yardzen.com/electronic-signature-disclosures"
            target="_blank"
            rel="noreferrer"
            className="text-blue-700 underline"
          >
            Consent to Electronic Signature and Disclosures
          </a>
          ,{" "}
          <a
            href="https://yardzen.com/terms-and-conditions"
            target="_blank"
            rel="noreferrer"
            className="text-blue-700 underline"
          >
            Terms of Service
          </a>{" "}
          and{" "}
          <a
            href="https://yardzen.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
            className="text-blue-700 underline"
          >
            Privacy Policy
          </a>
          .
        </p>
        <div className="pt-6 ">
          <AcceptTermsForm userId={userId} />
        </div>
      </div>
    </div>
  );
};
