"use client";

import { SegmentBrowserClient } from "@yardzen/segmentv2";
import { usePathname, useSearchParams } from "next/navigation";
import { createContext, useContext, useEffect, useMemo, useRef } from "react";
import { analytics } from "../index";

export interface ClientSegmentProviderProps {
  writeKey: string;
  children: React.ReactNode;
  initialUserId?: string;
  initialAnonymousId?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ctx = createContext<SegmentBrowserClient>(analytics);

export function useSegment() {
  return useContext(ctx);
}

export function ClientSegmentProvider(props: ClientSegmentProviderProps) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const previousPathname = useRef<string | null>(null);
  const client = useMemo(() => {
    analytics.setWriteKey(props.writeKey);
    analytics.setApiUrl("/api/s");

    if (props.initialUserId) {
      analytics.setUserId(props.initialUserId);
    }

    if (props.initialAnonymousId) {
      analytics.setAnonymousId(props.initialAnonymousId);
    } else if (typeof document !== "undefined") {
      const cookie = document.cookie.match(/yzsa=([^;]+)/)?.[1];
      if (cookie) {
        analytics.setAnonymousId(cookie);
      }
    }

    return analytics;
  }, [props.writeKey, props.initialUserId, props.initialAnonymousId]);

  useEffect(() => {
    if (previousPathname.current !== pathname) {
      const params = Object.fromEntries(searchParams.entries());
      analytics.page({
        properties: Object.keys(params).length > 0 ? params : undefined,
      });
      previousPathname.current = pathname;
    }
  }, [pathname, searchParams]);

  return <ctx.Provider value={client}>{props.children}</ctx.Provider>;
}
