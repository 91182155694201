import {
  CompositePropagator,
  W3CBaggagePropagator,
  W3CTraceContextPropagator,
} from "@opentelemetry/core";
import { WebTracerProvider } from "@opentelemetry/sdk-trace-web";
import {
  SimpleSpanProcessor,
  BatchSpanProcessor,
} from "@opentelemetry/sdk-trace-base";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { getWebAutoInstrumentations } from "@opentelemetry/auto-instrumentations-web";
import { Resource } from "@opentelemetry/resources";
import { SemanticResourceAttributes } from "@opentelemetry/semantic-conventions";
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";
import { ZoneContextManager } from "@opentelemetry/context-zone";
import {
  LogRocketSpanExporter,
  LogRocketSpanProcessor,
} from "./LogRocketSpanProcessor";

function getDefaultEnv() {
  if (typeof window !== "undefined") {
    if (window.location.hostname.includes("staging.")) {
      return "yardzen-staging";
    } else if (window.location.hostname.includes("yardzen.com")) {
      return "yardzen-5f8e9";
    }
  }
  return "development";
}

export const startFrontEndTracing = async (
  serviceName = "marketplace-client",
  environment = getDefaultEnv(),
) => {
  const provider = new WebTracerProvider({
    resource: new Resource({
      [SemanticResourceAttributes.SERVICE_NAME]: serviceName,
      [SemanticResourceAttributes.DEPLOYMENT_ENVIRONMENT]: environment,
    }),
  });

  const exporter = new OTLPTraceExporter({ url: "/api/trace" });

  provider.addSpanProcessor(new LogRocketSpanProcessor());
  provider.addSpanProcessor(
    new BatchSpanProcessor(new LogRocketSpanExporter()),
  );

  if (process.env.NODE_ENV === "production") {
    provider.addSpanProcessor(new BatchSpanProcessor(exporter));
  } else {
    provider.addSpanProcessor(new SimpleSpanProcessor(exporter));
  }

  const contextManager = new ZoneContextManager();

  provider.register({
    contextManager,
    propagator: new CompositePropagator({
      propagators: [
        new W3CBaggagePropagator(),
        new W3CTraceContextPropagator(),
      ],
    }),
  });

  registerInstrumentations({
    tracerProvider: provider,
    instrumentations: [
      getWebAutoInstrumentations({
        "@opentelemetry/instrumentation-fetch": {
          propagateTraceHeaderCorsUrls: /.*/,
          clearTimingResources: true,
          applyCustomAttributesOnSpan(span) {
            span.setAttribute("app.synthetic_request", "false");
          },
        },
      }),
    ],
  });
};
