//get the value of a cookie on the client side
export function getClientSideCookieValue(cookieName: string) {
  if (typeof document === "undefined") {
    return undefined;
  }

  const parts = document.cookie?.split(`; `);
  const cookie = parts.find((part) => part.startsWith(cookieName));
  const cookieValue = cookie?.split("=")[1];
  if (cookieValue) {
    return decodeURIComponent(cookieValue);
  }
}
