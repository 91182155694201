import { Loader } from "@googlemaps/js-api-loader";

type CityStateLocation = {
  city?: string;
  state?: string;
};

export async function getCityByZipCode(
  zipCode: string,
): Promise<CityStateLocation> {
  const loader = new Loader({
    apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || "",
    version: "quarterly",
  });
  const geocodingLibrary = await loader.importLibrary("geocoding");
  const geocoder = new geocodingLibrary.Geocoder();
  let results: google.maps.GeocoderResult[] = [];

  try {
    results = (await geocoder.geocode({ address: zipCode }))?.results;
  } catch (e) {
    console.error("Failed to get city by zip code", e);
    return {
      city: undefined,
      state: undefined,
    };
  }

  const city = results[0].address_components.find((component) =>
    component.types.includes("locality"),
  )?.short_name;
  const state = results[0].address_components.find((component) =>
    component.types.includes("administrative_area_level_1"),
  )?.short_name;

  return {
    city,
    state,
  };
}
