"use client";

import {
  Button,
  ButtonProps,
  CircularProgress,
  SxProps,
  Theme,
} from "@mui/material";
import { buttonClass } from "./classes";
import { analytics } from "@yardzen/next-segment";
import { useRouter } from "next/navigation";
import Link from "next/link";

interface YardzenButtonProps extends ButtonProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  loading?: boolean;
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => Promise<unknown> | unknown;
  trackingData?: {
    button_name: string;
    button_content: string;
    flow_name: string;
    sub_flow_name?: string;
  } & Record<string, string>;
  ariaLabel?: string;
}

export function YardzenButton({
  sx = [],
  children,
  variant,
  type,
  disabled,
  onClick,
  loading,
  href,
  trackingData,
  ariaLabel,
}: YardzenButtonProps) {
  const router = useRouter();

  function linksToExternalSite(href: string) {
    return /^https?:\/\//.test(href);
  }

  async function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (href) {
      event.preventDefault();
    }

    if (trackingData) {
      await analytics.trackClicked({ ...trackingData, click_type: "button" });
    }

    await onClick?.(event);

    if (href) {
      if (linksToExternalSite(href)) {
        window.location.href = href;
      } else {
        router.push(href);
      }
    }
  }

  function getLinkComponent() {
    if (!href) {
      return undefined;
    }

    if (linksToExternalSite(href)) {
      return "a";
    }

    return Link;
  }

  return (
    <Button
      variant={variant || "contained"}
      type={type || "button"}
      disabled={disabled}
      href={href}
      LinkComponent={getLinkComponent()}
      onClick={handleClick}
      aria-label={ariaLabel}
      sx={[
        buttonClass(variant),
        // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
      {loading && <CircularProgress size={16} sx={{ margin: "0.2rem" }} />}
    </Button>
  );
}
